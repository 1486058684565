import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import logo from "../../../Assets/logo4.png";
import "../../../Style/form.css";
import SignatureModal from "../SignatureManage/SignatureModal";
import { FaSignature } from "react-icons/fa";
import { AiFillCloud, AiOutlinePrinter } from "react-icons/ai";

const SBH_notice_privacy_practices = () => {
  const [caregiverSignature, setCaregiverSignature] = useState(false);
  const [providerSignature, setProviderSignature] = useState(false);
  const [ProviderImageURL, setProviderImageURL] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  console.log("ProviderImageURL", ProviderImageURL);
  console.log("imageURL", imageURL);
  const [file, setFile] = useState();

  console.log("file", file);

  const handleSignatureProvider = () => {
    setProviderSignature(true);
  };
  const handleSignatureCaregiver = () => {
    setCaregiverSignature(true);
  };
  const handleSignatureClose = () => {
    setCaregiverSignature(false);
    setProviderSignature(false);
  };

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <div>
        <div className="form-border 2xl:w-[70%] w-full mx-auto p-5 bg-white">
          <div>
            <div className="flex items-center flex-wrap gap-3 justify-between">
              <img src={logo} alt="" />
              <div className="text-[16px] sm:mr-10 mr-0 ml-10 sm:ml-0">
                <p className="my-1">
                  <span className="form-head">Mail: </span>demo@example.com
                </p>
                <p className="my-1">
                  <Link to={"#"}>
                    <span className="form-head">Email: </span>demo@example.com
                  </Link>
                </p>
                <p className="my-1">
                  <span className="form-head">Phone: </span> 000-000-0000
                </p>
                <p className="my-1">
                  <Link to={"#"}>
                    <span className="form-head">Fax: </span>000.000.0000
                  </Link>
                </p>
              </div>
            </div>
            <div className="form-title mb-5">
              <h1>SBH NOTICE OF PRIVACY PRACTICES</h1>
              <div className="py-[3px] my-3 w-36 mx-auto bg-[#d9534f]"></div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {" "}
            <div>
              <p className="text-base font-normal mt-2">
                THIS NOTICE DESCRIBES HOW HEALTH INFORMATION MAY BE USED AND
                DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE
                REVIEW IT CAREFULLY.
              </p>

              <div>
                <div className="my-8">
                  <h1 className="form-input-name-black px-2">
                    I. OUR PLEDGE REGARDING HEALTH INFORMATION:
                  </h1>
                  <p className="text-base font-medium my-2 px-4">
                    We understand that health information about you and your
                    health care is personal. We committed to protecting health
                    information about you. We create a record of the care and
                    services you receive from us. We need this record to provide
                    you with quality care and to comply with certain legal
                    requirements. This notice applies to all of the records of
                    your care generated by this health care practice. This
                    notice will tell you about the ways in which we may use and
                    disclose health information about you. We also describe your
                    rights to the health information we keep about you, and
                    describe certain obligations we have regarding the use and
                    disclosure of your health information.We required by law to:
                  </p>
                  <ul className="list-disc font-[16px] px-16 gap-5">
                    <li>
                      Make sure that protected health information (“PHI”) that
                      identifies you is kept private.
                    </li>
                    <li>
                      Give you this notice of my legal duties and privacy
                      practices with respect to health information
                    </li>
                    <li>
                      Follow the terms of the notice that is currently in
                      effect.
                    </li>
                    <li>
                      I can change the terms of this Notice, and such changes
                      will apply to all information I have about you. The new
                      Notice will be available upon request, in my office, and
                      on my website.
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <h1 className="form-input-name-black px-2">
                    II. HOW WE MAY USE AND DISCLOSE HEALTH INFORMATION ABOUT
                    YOU:
                  </h1>
                  <p className="text-base font-normal my-2 px-4">
                    The following categories describe different ways that we use
                    and disclose health information. For each category of uses
                    or disclosures we will explain what we mean and try to give
                    some examples. Not every use or disclosure in a category
                    will be listed. However, all of the ways we are permitted to
                    use and disclose information will fall within one of the
                    categories. For Treatment Payment, or Health Care
                    Operations: Federal privacy rules (regulations) allow health
                    care providers who have direct treatment relationship with
                    the patient/client to use or disclose the patient/client’s
                    personal health information without the patient’s written
                    authorization, to carry out the health care provider’s own
                    treatment, payment or health care operations. We may also
                    disclose your protected health information for the treatment
                    activities of any health care provider. This too can be done
                    without your written authorization. For example, if a
                    clinician were to consult with another licensed health care
                    provider about your condition, we would be permitted to use
                    and disclose your personal health information, which is
                    otherwise confidential, in order to assist the clinician in
                    diagnosis and treatment of your mental health condition.
                    Disclosures for treatment purposes are not limited to the
                    minimum necessary standard. Because therapists and other
                    health care providers need access to the full record and/or
                    full and complete information in order to provide quality
                    care. The word “treatment” includes, among other things, the
                    coordination and management of health care providers with a
                    third party, consultations between health care providers and
                    referrals of a patient for health care from one health care
                    provider to another. Lawsuits and Disputes: If you are
                    involved in a lawsuit, we may disclose health information in
                    response to a court or administrative order. We may also
                    disclose health information about your child in response to
                    a subpoena, discovery request, or other lawful process by
                    someone else involved in the dispute, but only if efforts
                    have been made to tell you about the request or to obtain an
                    order protecting the information requested.
                  </p>
                </div>
                <div className="my-8">
                  <h1 className="form-input-name-black px-2">
                    III. CERTAIN USES AND DISCLOSURES DO NOT REQUIRE YOUR
                    AUTHORIZATION.
                  </h1>
                  <p className="text-base font-medium my-2 px-4">
                    Subject to certain limitations in the law, I can use and
                    disclose your PHI without your Authorization for the
                    following reasons:
                  </p>
                  <ol className=" list-decimal font-[16px] px-16 gap-5">
                    <li>
                      When disclosure is required by state or federal law, and
                      the use or disclosure complies with and is limited to the
                      relevant requirements of such law.
                    </li>
                    <li>
                      For public health activities, including reporting
                      suspected child, elder, or dependent adult abuse, or
                      preventing or reducing a serious threat to anyone’s health
                      or safety.
                    </li>
                    <li>
                      For health oversight activities, including audits and
                      investigations.
                    </li>
                    <li>
                      For judicial and administrative proceedings, including
                      responding to a court or administrative order, although
                      our preference is to obtain an Authorization from you
                      before doing so.
                    </li>
                    <li>
                      For law enforcement purposes, including reporting crimes
                      occurring on our premises
                    </li>
                    <li>
                      To coroners or medical examiners, when such individuals
                      are performing duties authorized by law.
                    </li>
                    <li>
                      For research purposes, including studying and comparing
                      clients who received one form of therapy versus those who
                      received another form of therapy for the same condition.
                    </li>
                    <li>
                      Specialized government functions, including, ensuring the
                      proper execution of military missions; protecting the
                      President of the United States; conducting intelligence or
                      counterintelligence operations; or, helping to ensure the
                      safety of those working within or housed in correctional
                      institutions.
                    </li>
                    <li>
                      For workers’ compensation purposes. Although our
                      preference is to obtain an Authorization from you, we may
                      provide your PHI in order to comply with workers’
                      compensation laws.
                    </li>
                    <li>
                      Appointment reminders and health related benefits or
                      services. We may use and disclose your PHI to contact you
                      to remind you that you have an appointment with us. We may
                      also use and disclose your PHI to tell you about treatment
                      alternatives, or other health care services or benefits
                      that we offer.
                    </li>
                  </ol>
                </div>
                <div className="my-8">
                  <h1 className="form-input-name-black px-2 my-4">
                    IV. CERTAIN USES AND DISCLOSURES REQUIRE YOU TO HAVE THE
                    OPPORTUNITY TO OBJECT:
                  </h1>

                  <ol className=" list-decimal font-[16px] px-16 gap-5">
                    <li>
                      Disclosures to family, friends, or others. We may provide
                      your PHI to a family member, friend, or other person that
                      you indicate is involved in your care or the payment for
                      your health care, unless you object in whole or in part.
                      The opportunity to consent may be obtained retroactively
                      in emergency situations.
                    </li>
                  </ol>
                </div>{" "}
                <div className="my-8">
                  <h1 className="form-input-name-black px-2 my-4">
                    V. YOU HAVE THE FOLLOWING RIGHTS WITH RESPECT TO YOUR PHI:
                  </h1>

                  <ol className=" list-decimal font-[16px] px-16 gap-5">
                    <li>
                      The Right to Request Limits on Uses and Disclosures of
                      Your PHI. You have the right to ask me not to use or
                      disclose certain PHI for treatment, payment, or health
                      care operations purposes. We are not required to agree to
                      your request, and we may say “no” if we believe it would
                      affect your health care
                    </li>
                    <li>
                      The Right to Request Restrictions for Out-of-Pocket
                      Expenses Paid for In Full. You have the right to request
                      restrictions on disclosures of your PHI to health plans
                      for payment or health care operations purposes if the PHI
                      pertains solely to a health care item or a health care
                      service that you have paid for out-of-pocket in full.
                    </li>
                    <li>
                      The Right to Choose How I Send PHI to You. You have the
                      right to ask us to contact you in a specific way (for
                      example, home or office phone) or to send mail to a
                      different address, and we will agree to all reasonable
                      requests.
                    </li>
                    <li>
                      The Right to See and Get Copies of Your PHI. Other than
                      “session notes,” you have the right to get an electronic
                      or paper copy of your medical record and other information
                      that we have about you. We will provide you with a copy of
                      your record, or a summary of it, if you agree to receive a
                      summary, within 30 days of receiving your written request,
                      and we may charge a reasonable, cost based fee for doing
                      so.
                    </li>
                    <li>
                      The Right to Get a List of the Disclosures We Have
                      Made.You have the right to request a list of instances in
                      which we have disclosed your PHI for purposes other than
                      treatment, payment, or health care operations, or for
                      which you provided us with an Authorization. We will
                      respond to your request for an accounting of disclosures
                      within 60 days of receiving your request. The list we will
                      give you will include disclosures made in the last six
                      years unless you request a shorter time. We will provide
                      the list to you at no charge, but if you make more than
                      one request in the same year, we will charge you a
                      reasonable cost based fee for each additional request.
                    </li>
                    <li>
                      The Right to Correct or Update Your PHI. If you believe
                      that there is a mistake in your PHI, or that a piece of
                      important information is missing from your PHI, you have
                      the right to request that we correct the existing
                      information or add the missing information. We may say
                      “no” to your request, but we will tell you why in writing
                      within 60 days of receiving your request.
                    </li>
                    <li>
                      The Right to Get a Paper or Electronic Copy of this
                      Notice. You have the right to get a paper copy of this
                      Notice, and you have the right to get a copy of this
                      notice by e-mail. And, even if you have agreed to receive
                      this Notice via e-mail, you also have the right to request
                      a paper copy of it.
                    </li>
                  </ol>
                </div>
                <div className="my-8">
                  <h1 className="form-input-name-black px-2 my-4">
                    Your Information. Your Rights. Our Responsibilities.
                  </h1>

                  <p className="text-base font-medium my-4 px-4">
                    This notice describes how medical information about you may
                    be used and disclosed and how you can get access to this
                    information. Please review it carefully. Your Rights
                  </p>
                  <p className="text-base font-medium my-4 px-4">Your Rights</p>
                  <p className="text-base font-medium my-4 px-4">
                    You have the right to:
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      Get a copy of your paper or electronic medical record
                    </li>
                    <li>Correct your paper or electronic medical record</li>
                    <li>Request confidential communication</li>
                    <li>Ask us to limit the information we share</li>
                    <li>
                      Get a list of those with whom we’ve shared your
                      information
                    </li>
                    <li>Get a copy of this privacy notice</li>
                    <li>Choose someone to act for you</li>
                    <li>
                      File a complaint if you believe your privacy rights have
                      been violated
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Your Choices
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    You have some choices in the way that we use and share
                    information as we:
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>Tell family and friends about your condition</li>
                    <li>Provide disaster relief</li>
                    <li>Include you in a hospital directory</li>
                    <li>Provide mental health care</li>
                    <li>Market our services and sell your information</li>
                    <li>Raise funds</li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Our Uses and Disclosures
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We may use and share your information as we:
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>Treat you</li>
                    <li>Run our organization</li>
                    <li>Bill for your services</li>
                    <li>Help with public health and safety issues</li>
                    <li>Do research</li>
                    <li>Comply with the law</li>
                    <li>Work with a medical examiner or funeral director</li>
                    <li>Help with public health and safety issues</li>
                    <li>
                      Address workers’ compensation, law enforcement, and other
                      government requests
                    </li>
                    <li>Respond to lawsuits and legal actions</li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">Your Rights</p>
                  <p className="text-base font-medium my-4 px-4">
                    When it comes to your health information, you have certain
                    rights. This section explains your rights and some of our
                    responsibilities to help you.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Get an electronic or paper copy of your medical record
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can ask to see or get an electronic or paper copy of
                      your medical record and other health information we have
                      about you. Ask us how to do this.{" "}
                    </li>
                    <li>
                      We will provide a copy or a summary of your health
                      information, usually within 30 days of your request. We
                      may charge a reasonable, cost-based fee.
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Ask us to correct your medical record
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can ask us to correct health information about you
                      that you think is incorrect or incomplete. Ask us how to
                      do this.
                    </li>
                    <li>
                      We may say “no” to your request, but we’ll tell you why in
                      writing within 60 days
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Request confidential communications
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can ask us to contact you in a specific way (for
                      example, home or office phone) or to send mail to a
                      different address.{" "}
                    </li>
                    <li>We will say “yes” to all reasonable requests.</li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Ask us to limit what we use or share
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can ask us not to use or share certain health
                      information for treatment, payment, or our operations. We
                      are not required to agree to your request, and we may say
                      “no” if it would affect your care.
                    </li>
                    <li>
                      If you pay for a service or health care item out-of-pocket
                      in full, you can ask us not to share that information for
                      the purpose of payment or our operations with your health
                      insurer. We will say “yes” unless a law requires us to
                      share that information.
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Get a list of those with whom we’ve shared information
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can ask for a list (accounting) of the times we’ve
                      shared your health information for six years prior to the
                      date you ask, who we shared it with, and why.
                    </li>
                    <li>
                      We will include all the disclosures except for those about
                      treatment, payment, and health care operations, and
                      certain other disclosures (such as any you asked us to
                      make). We’ll provide one accounting a year for free but
                      will charge a reasonable, cost-based fee if you ask for
                      another one within 12 months.
                    </li>
                  </ul>
                </div>
                <div className="my-8">
                  <p className="text-base font-medium my-4 px-4">
                    Get a copy of this privacy notice
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    You can ask for a paper copy of this notice at any time,
                    even if you have agreed to receive the notice
                    electronically. We will provide you with a paper copy
                    promptly.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Choose someone to act for you
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      If you have given someone medical power of attorney or if
                      someone is your legal guardian, that person can exercise
                      your rights and make choices about your health
                      information.
                    </li>
                    <li>
                      We will make sure the person has this authority and can
                      act for you before we take any action.
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    File a complaint if you feel your rights are violated
                  </p>

                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      You can complain if you feel we have violated your rights
                      by contacting us using the information on page 1
                    </li>
                    <li>
                      You can file a complaint with the U.S. Department of
                      Health and Human Services Office for Civil Rights by
                      sending a letter to 200 Independence Avenue, S.W.,
                      Washington, D.C. 20201, calling 1- 877-696-6775, or
                      visiting www.hhs.gov/ocr/privacy/hipaa/complaints/.
                    </li>
                    <li>
                      We will not retaliate against you for filing a complaint.
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    Your Choices
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    For certain health information, you can tell us your choices
                    about what we share. If you have a clear preference for how
                    we share your information in the situations described below,
                    talk to us. Tell us what you want us to do, and we will
                    follow your instructions.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    In these cases, you have both the right and choice to tell
                    us to:
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      Share information with your family, close friends, or
                      others involved in your care
                    </li>
                    <li>Share information in a disaster relief situation</li>
                    <li>Include your information in a hospital directory</li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    If you are not able to tell us your preference, for example
                    if you are unconscious, we may go ahead and share your
                    information if we believe it is in your best interest. We
                    may also share your information when needed to lessen a
                    serious and imminent threat to health or safety.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    In these cases we never share your information unless you
                    give us written permission:
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>Marketing purposes</li>
                    <li>Sale of your information</li>
                    <li>Most sharing of psychotherapy notes</li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    In the case of fundraising:
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      We may contact you for fundraising efforts, but you can
                      tell us not to contact you again
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    Our Uses and Disclosures
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    How do we typically use or share your health information? We
                    typically use or share your health information in the
                    following ways.
                  </p>
                  <p className="text-base font-medium my-4 px-4">Treat you</p>
                  <p className="text-base font-medium my-4 px-4">
                    We can use your health information and share it with other
                    professionals who are treating you. Example: A doctor
                    treating you for an injury asks another doctor about your
                    overall health condition.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Run our organization
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We can use and share your health information to run our
                    practice, improve your care, and contact you when necessary.
                    Example: We use health information about you to manage your
                    treatment and services.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Bill for your services
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We can use and share your health information to bill and get
                    payment from health plans or other entities. Example: We
                    give information about you to your health insurance plan so
                    it will pay for your services.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    How else can we use or share your health information?
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We are allowed or required to share your information in
                    other ways – usually in ways that contribute to the public
                    good, such as public health and research. We have to meet
                    many conditions in the law before we can share your
                    information for these purposes. For more information see:
                    <Link
                      to="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html"
                      target="_blank"
                    >
                      www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html
                    </Link>
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Help with public health and safety issues We can share
                    health information about you for certain situations such as:
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>Preventing disease</li>
                    <li>Helping with product recalls</li>
                    <li>Reporting adverse reactions to medications</li>
                    <li>
                      Reporting suspected abuse, neglect, or domestic violence
                    </li>
                    <li>
                      Preventing or reducing a serious threat to anyone’s health
                      or safety
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">Do research</p>
                  <p className="text-base font-medium my-4 px-4">
                    We can use or share your information for health research.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Comply with the law
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We will share information about you if state or federal laws
                    require it, including with the Department of Health and
                    Human Services if it wants to see that we’re complying with
                    federal privacy law.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Respond to organ and tissue donation requests
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We can share health information about you with organ
                    procurement organizations.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Work with a medical examiner or funeral director
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We can share health information with a coroner, medical
                    examiner, or funeral director when an individual dies.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Address workers’ compensation, law enforcement, and other
                    government requests We can use or share health information
                    about you:
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>For workers’ compensation claims</li>
                    <li>
                      For law enforcement purposes or with a law enforcement
                      official
                    </li>
                    <li>
                      With health oversight agencies for activities authorized
                      by law
                    </li>
                    <li>
                      For special government functions such as military,
                      national security, and presidential protective services
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    Respond to lawsuits and legal actions We can share health
                    information about you in response to a court or
                    administrative order, or in response to a subpoena
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Our Responsibilities
                  </p>
                  <ul className=" list-disc font-[16px] px-16 gap-5">
                    <li>
                      We are required by law to maintain the privacy and
                      security of your protected health information.
                    </li>
                    <li>
                      We will let you know promptly if a breach occurs that may
                      have compromised the privacy or security of your
                      information.
                    </li>
                    <li>
                      We must follow the duties and privacy practices described
                      in this notice and give you a copy of it.{" "}
                    </li>
                    <li>
                      We will not use or share your information other than as
                      described here unless you tell us we can in writing. If
                      you tell us we can, you may change your mind at any time.
                      Let us know in writing if you change your mind
                    </li>
                  </ul>
                  <p className="text-base font-medium my-4 px-4">
                    For more information see:{" "}
                    <Link
                      to="https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html"
                      className="text-secondary"
                      target="_blank"
                    >
                      https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html.
                    </Link>
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Changes to the Terms of this Notice
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    We can change the terms of this notice, and the changes will
                    apply to all information we have about you. The new notice
                    will be available upon request, in our office, and on our
                    website.
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Acknowledgement of Receipt of Privacy Notice
                  </p>
                  <p className="text-base font-medium my-4 px-4">
                    Under the Health Insurance Portability and Accountability
                    Act of 1996 (HIPAA), you have certain rights regarding the
                    use and disclosure of your protected health information. By
                    checking the box below, you are acknowledging that you have
                    received a copy of HIPAA Notice of Privacy Practices
                  </p>
                </div>
                <div className="overflow-x-scroll my-7">
                  <table class="min-w-full border-2 border-blue-600 ">
                    <tbody>
                      {" "}
                      <tr class="border-b border-2 border-blue-600 ">
                        <td
                          class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 "
                          colSpan={"2"}
                        >
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Parent/Guardian #1: (Print Name)
                              </label>
                            </span>
                            <br />
                            <input
                              type="date"
                              className=" w-full border-none focus:outline-none "
                              placeholder="Enter Here..."
                              {...register("client_name")}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr class="border-b border-2 border-blue-600 ">
                        <td class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 ">
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Parent/Guardian #1: (Signature)
                              </label>
                            </span>

                            <button
                              className="flex items-center text-lg hover:underline hover:text-rose-800 mx-auto font-medium gap-1 text-[#207ac7]"
                              onClick={handleSignatureProvider}
                            >
                              Provider Signature
                              <FaSignature className="text-lg" />
                            </button>
                          </div>
                        </td>
                        <td class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 ">
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Date
                              </label>
                            </span>

                            <input
                              type="date"
                              className=" w-full border-none focus:outline-none "
                              placeholder="Enter Here..."
                              {...register("client_name")}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr class="border-b border-2 border-blue-600 ">
                        <td
                          class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 "
                          colSpan={"2"}
                        >
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Parent/Guardian #2: (Print Name)
                              </label>
                            </span>
                            <br />
                            <div>
                              <button
                                className="flex items-center text-lg hover:underline hover:text-rose-800 mx-auto font-medium gap-1 text-[#207ac7]"
                                onClick={handleSignatureProvider}
                              >
                                Caregiver Signature{" "}
                                <FaSignature className="text-lg" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-b border-2 border-blue-600 ">
                        <td class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 ">
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Parent/Guardian #2: (Signature)
                              </label>
                            </span>

                            <input
                              type="text"
                              className=" w-full border-none focus:outline-none "
                              placeholder="Enter Here..."
                              {...register("client_name")}
                            />
                          </div>
                        </td>
                        <td class="text-sm text-gray-900 font-light px-2 py-3 whitespace-nowrap border-r border-2 border-blue-600 ">
                          <div class="flex gap-3">
                            <span>
                              <label
                                for="rec_name"
                                className=" font-bold text-base"
                              >
                                Date
                              </label>
                            </span>

                            <input
                              type="date"
                              className=" w-full border-none focus:outline-none "
                              placeholder="Enter Here..."
                              {...register("client_name")}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {caregiverSignature && (
                <SignatureModal
                  handleSignatureClose={handleSignatureClose}
                  open={caregiverSignature}
                  setImageURL={setImageURL}
                  setFile={setFile}
                ></SignatureModal>
              )}

              {providerSignature && (
                <SignatureModal
                  handleSignatureClose={handleSignatureClose}
                  open={providerSignature}
                  setImageURL={setProviderImageURL}
                  setFile={setFile}
                ></SignatureModal>
              )}
              <div className="flex items-center justify-between my-12">
                <button className=" bg-purple-900 text-white flex items-center px-2 py-3 gap-1 text-lg font-semibold rounded-md my-2">
                  <AiFillCloud /> Save
                </button>
                <button className=" bg-cyan-900 text-white flex items-center px-2 py-3 gap-1 text-lg font-semibold rounded-md my-2">
                  <AiOutlinePrinter /> Print
                </button>
              </div>

              <div className="flex flex-wrap gap-2 items-center justify-between form-footer">
                <div className="text-black">
                  Demo Institution{" "}
                  <span className=" font-normal">somewhere in america</span>
                </div>
                <div>
                  Phone: 000-000-0000, Email: demo@example.com, Fax:
                  000.000.0000, example.com
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SBH_notice_privacy_practices;
