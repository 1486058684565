import React from "react";
import InsuranceExclusionMultiSelection from "./InsuranceExclusion/InsuranceExclusionMultiSelection";

const InsuranceExclusion = () => {
  return (
    <div>
      <InsuranceExclusionMultiSelection></InsuranceExclusionMultiSelection>
    </div>
  );
};

export default InsuranceExclusion;
